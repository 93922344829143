/**
 * Launchpad Titles
 */

export const lpOverviewTitle = 'Overview'

export const lpWoTitle = 'Work Opportunities'

export const lpTalentTitle = 'Talents'

export const lpAddFormTitle = 'Add Dataset'

export const lpLabelTitle = 'Labels'

export const lpDatasetTitle = 'Data Import'

export const TALENT_PROFILE_TITLE = 'Talent Profile'

export const DATA_GATHERING_TALENT_TITLE = 'Data Gathering Talent'

/**
 * Mission control Titles
 */

export const mcTitle = 'Mission Control'

export const mcAddFormTitle = 'Start Optimization'

/**
 * Dashboard Titles
 */

export const settingsTitle = 'Settings'

/* Admin Title */
export const dataResetTitle = 'Data Reset'
