import {
  confirmPasswordConst,
  defaultTrueValidation,
  email,
  MAX_LENGTH,
  MAX_LENGTH_GLOBAL,
  MAX_LENGTH_OPTIMIZATION,
  MIN_LENGTH_NUMBER,
  MIN_MAX_DATE,
  minMaxDate,
  NOT_EMPTY_WITH_SPACE_MAX_LENGTH,
  notEmptyOrNull,
  notEmptyWithSpace,
  number,
  numberWithPercentage,
  ONE_ALPHABETIC_CHARACTER,
  password,
  percentageValue,
  POSITIVE_NUMBER,
  stringValidation,
  yearWithPrefix,
  zipCode,
} from './constant'
import {
  checkPercentageValue,
  customMinMaxDateValidation,
  equal,
  minMaxDateValid,
  minMaxDateValidation,
} from './javascript'
import {
  alphabeticStringValidation,
  checkNotEmptyWithSpace,
  checkNotEmptyWithSpaceMaxLength,
  checkNumberWithPostfix,
  confirmPassword,
  emailValidation,
  mobileNumber,
  notEmpty,
  oneCharacterAlphabeticStringValidation,
  positiveNumber,
  validateYearString,
  zipCodeValidation,
} from './regex'

let passwordValue = ''

export default (
  pattern,
  value,
  min = null,
  max = null,
  minDate = null,
  maxDate = null,
  periodName = null,
) => {
  if (equal(pattern, password)) {
    passwordValue = value
  }
  switch (pattern) {
    case notEmptyOrNull:
    case password:
      return notEmpty(value)
    case POSITIVE_NUMBER:
      return positiveNumber(value, min, max)
    case MIN_MAX_DATE:
      return customMinMaxDateValidation(minDate, maxDate)
    case ONE_ALPHABETIC_CHARACTER:
      return oneCharacterAlphabeticStringValidation(value, pattern)
    case yearWithPrefix:
      return validateYearString(value)
    case zipCode:
      return zipCodeValidation(value)
    case email:
      return emailValidation(value)
    case confirmPasswordConst:
      return confirmPassword(value, passwordValue)
    case number:
      return mobileNumber(value)
    case stringValidation:
      return alphabeticStringValidation(value)
    case numberWithPercentage:
      return checkNumberWithPostfix(value)
    case percentageValue:
      return checkPercentageValue(value)
    case notEmptyWithSpace:
      return checkNotEmptyWithSpace(value)
    case NOT_EMPTY_WITH_SPACE_MAX_LENGTH:
      return checkNotEmptyWithSpaceMaxLength(
        value,
        MIN_LENGTH_NUMBER,
        MAX_LENGTH_OPTIMIZATION,
      )
    case MAX_LENGTH:
      return checkNotEmptyWithSpaceMaxLength(
        value,
        MIN_LENGTH_NUMBER,
        MAX_LENGTH_GLOBAL,
      )
    case minMaxDate:
      return (
        min &&
        max &&
        periodName &&
        minMaxDateValidation(value, min, max, periodName) &&
        minMaxDateValid(periodName, value)
      )
    case defaultTrueValidation:
      return true
    default:
      return false
  }
}
