import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import GroupNotFound from '../presentation/GroupNotFound'
import PermissionDenied from '../presentation/PermissionDenied'
import Title from '../shared/Title'
import { ROLE_ADMIN, STRING } from '../utils/constant'
import {
  checkIncludes,
  checkUndefined,
  equal,
  keys,
  ternary,
  typeOf,
} from '../utils/javascript'
import { fallbackRoutes } from './routes.description'

const finalComponent = (
  isAuth,
  isUserLoggedIn,
  Component,
  pathname,
  props,
  title,
  metaContent,
  isLogin,
  hasGroup,
  notFoundMessage,
  pagePermission,
  role,
  onlyAdmin,
) => {
  const hasPermission = ternary(
    typeOf(pagePermission, STRING),
    checkIncludes(pagePermission, keys(role?.resource_access)),
    pagePermission,
  )
  const FinalRedirectUrl = ternary(
    hasGroup || checkUndefined(hasGroup),
    <Redirect to={{ pathname }} />,
    <GroupNotFound />,
  )

  const CheckPermission = hasPermission ? (
    <>
      <Title title={title} content={metaContent} />
      {ternary(
        !Component,
        FinalRedirectUrl,
        <Component {...props} auth={!!isLogin} />,
      )}
    </>
  ) : (
    <PermissionDenied />
  )

  const FinalComponent = ternary(
    hasGroup || checkUndefined(hasGroup),
    onlyAdmin ? (
      equal(role?.name, ROLE_ADMIN) ? (
        CheckPermission
      ) : (
        <PermissionDenied />
      )
    ) : (
      CheckPermission
    ),
    <GroupNotFound />,
  )

  if (isAuth) return ternary(isLogin, FinalRedirectUrl, FinalComponent)
  if (isUserLoggedIn) return ternary(isLogin, FinalComponent, FinalRedirectUrl)
  return FinalComponent
}

const RenderRoutes = ({ routes, isLogin }) => {
  const { hasGroup, notFoundMessage, role } = useSelector((state) => state.app)

  const inferFallbackRedirectUrlFromPermissions = () => {
    if (isLogin) {
      const accessKeys = Object.keys(role?.resource_access)
      return fallbackRoutes.find((route) =>
        accessKeys.includes(route.pagePermission),
      )?.redirectUrl
    }
    return ''
  }

  return (
    <Switch>
      {routes.map(
        (
          {
            isAuth,
            isUserLoggedIn,
            component,
            title,
            redirectUrl,
            metaContent,
            pagePermission,
            onlyAdmin,
            ...rest
          },
          index,
        ) => {
          const loginRedirectURL = inferFallbackRedirectUrlFromPermissions()
          return (
            <Route
              {...rest}
              key={index}
              render={(props) =>
                finalComponent(
                  isAuth,
                  isUserLoggedIn,
                  component,
                  loginRedirectURL || redirectUrl,
                  props,
                  title,
                  metaContent,
                  isLogin,
                  hasGroup,
                  notFoundMessage,
                  pagePermission,
                  role,
                  onlyAdmin,
                )
              }
            />
          )
        },
      )}
    </Switch>
  )
}

export default memo(RenderRoutes)
