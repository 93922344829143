/**
 * Login form path
 */
export const loginFormPath = 'login'
export const loginDetailPath = 'loginDetail'
export const checkLogin = 'checkLogin'
export const motomoStatus = 'motomoStatus'
export const sentryStatus = 'sentryStatus'

/**
 * Mission form path
 */

export const missionFormPath = 'mission'

export const missionOverview = 'missionOverview'

export const missionDetailsPath = 'details'
export const missionParamsPath = 'parameters'

export const missionDetails = 'missionDetails'

export const kpiDatasetPath = 'kpiDataset'
export const kpiOptimizationDatasetPath = 'optimization'
export const kpiCumulativeDatasetPath = 'kpiCumulative'
export const kpiPath = 'kpi'

export const missionTemplateFormPath = 'templates'

export const detailedChangePath = 'detailedChange'

export const setEmployee = 'setEmployee'

export const jobManagers = 'jobManagers'

export const plannings = 'plannings'

export const optimizationFormPath = 'optimization'

export const missionEmployeePath = 'missionTalents'

export const missionBookingPath = 'missionBooking'

export const missionOperatingUnit = 'operatingUnit'

export const checkOptimizationStatus = 'checkOptimizationStatus'

export const talentProfile = 'talentProfile'

export const survey = 'survey'

/**
 * Report form path
 */

export const reportFormPath = 'report'

/**
 * Launchpad form paths
 */

export const launchpadFormPath = 'launchpad'

export const launchpadHistoryTableFormPath = 'historyTable'

export const launchpadOverviewFormPath = 'overview'

export const launchpadImportFormPath = 'importDetails'

export const launchpadImportHistoryTableFormPath = 'importHistoryTable'

export const launchpadImportHistoryTableChildFormPath =
  'importHistoryTableChild'

export const launchpadWorkOpportunitiesFormPath = 'workOpportunities'

export const launchpadTalentFormPath = 'talent'

export const launchpadJobDetailsFormPath = 'jobDetails'

export const launchpadTalents = 'talents'

export const launchpadEditTalentFormPath = 'edit-talent'

export const launchpadEditDemandFormPath = 'edit-demand'

export const launchpadAddDemandFormPath = 'add-demand'

export const launchpadEditHoursFormPath = 'edit-hours'

export const launchpadGrade = 'grades'

export const launchpadLabels = 'labels'

export const launchpadLabelsCategory = 'labelCategory'

export const launchpadEditLabelsCategory = 'edit-label-category'

export const checkDatasetStatus = 'checkDatasetStatus'

export const setLiveOpt = 'setLiveOptimization'

export const getReport = 'getReport'

export const labelModalFormPath = 'label-modal'

/**
 * Settings paths
 */

export const settingsPath = 'settings'

export const planningsDetails = 'planningsDetails'

export const getFields = 'getFields'

export const validateDataset = 'validateDataset'

export const importDataset = 'importDataset'

export const userManualPath = 'user-manual'

export const talentDatasets = 'talentDatasets'

export const filterChipConstant = 'filterChip'

export const WoRowUpdate = 'acceptance-row'

export const WoBulkUpdate = 'bulkUpdate'

export const redirectPlanningQuery = {
  key: 'to',
  val: 'plannings',
}

export const missionWOPath = {
  parent: missionFormPath,
  child: launchpadWorkOpportunitiesFormPath,
}
