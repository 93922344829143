import { forwardSlashSymbol, minusSymbol, percentageSymbol } from './symbols'

/**
 * Copyright
 */
export const copyRight = () => `© 2015-${new Date().getFullYear()} `

/**
 * CSS
 */

export const fullWidth = true

export const margin = 'normal'

/**
 * Redirection
 */

export const redirectTo = {
  dashboard: '/dashboard/kpis',
  login: '/login',
  launchpad: 'launchpad',
  homepage: 'home-page',
}

/**
 * API Endpoints
 */

export const jobManagers = 'job_managers'
export const dataset = 'dataset'
export const optimization = 'optimization'
export const plannings = 'plannings'
export const workOpportunities = 'bookings'
export const workopportunity = 'work-opportunities'
export const missionControl = 'missionControl'
export const employees = 'employees'
export const talents = 'talents'
export const graphData = 'graphData'
export const jobDetails = 'job-details'
export const label = 'labels'
export const modifiedLabel = 'modifiedLabel'
export const filterData = 'filterData'
export const cardData = 'cardData'
export const resetData = 'reset_data'
export const singlePlanning = 'planning'
export const apiEndPoints = {
  token: 'token',
  session: 'session',
  datasets: 'datasets',
  optimizations: 'optimizations',
  baselines: 'baselines',
  talents: 'employees',
  importHistory: 'import_history',
  datasetOverview: 'overview',
  grades: 'grades',
  workOpportunities,
  static: 'static',
  templates: 'templates',
  kpi: 'kpi',
  dataset,
  optimization,
  group: 'group',
  defaults: 'defaults',
  jobManagers,
  employees,
  skills: 'skills',
  plannings,
  singlePlanning,
  label,
  labelCategory: 'labelcategories',
  operatingUnit: 'operating_units',
  checkUpload: 'check_upload',
  checkOptimization: 'check_optimization',
  setLive: 'set_live',
  getReportList: 'get_report_list',
  suggestedEmployee: 'suggested_employees',
  assignedEmployee: 'assigned_employees',
  setEmployee: 'set_employee',
  getFields: 'get_fields',
  validateFields: 'validate',
  dataImport: 'import',
  userManual: 'user_manual',
  changePassword: 'change_password',
  getFileDetail: 'get_file_details',
  admin: 'admin',
  getOptStats: 'get_acceptance_state_stats',
  bulkUpdate: 'bulk_update',
  resetData,
  motomo: 'config/app',
  sentryTunnel: 'api/sentry',
  sentry: 'config/react_sentry',
  surveys: 'surveys',
  publish: 'publish',
  optimizationSettings: 'settings',
  savedReports: 'reports',
  bookingPeriods: 'booking_periods',
  integrationImport: 'integration_import',
  userDevices: 'user_devices',
}

/**
 * Methods
 */

export const method = {
  post: 'post',
  get: 'get',
  delete: 'delete',
  put: 'put',
}

/**
 * Input field
 */

export const fileFormatSelect = 'File Format'

export const startYear = 'FY'

export const numberPostFix = percentageSymbol

export const accept =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12'

export const successStatus = [200, 201, 204]

export const notEmptyOrNull = 'notEmptyOrNull'

export const yearWithPrefix = 'yearWithPrefix'

export const numberWithPercentage = 'numberWithPercentage'

export const zipCode = 'zipCode'

export const password = 'password'

export const email = 'email'

export const confirmPasswordConst = 'confirmPassword'

export const number = 'number'

export const defaultTrueValidation = 'defaultTrueValidation'

export const stringValidation = 'stringValidation'

export const notEmptyWithSpace = 'notEmptyWithSpace'
export const ONE_ALPHABETIC_CHARACTER = 'ONE_ALPHABETIC_CHARACTER'

export const POSITIVE_NUMBER = 'POSITIVE_NUMBER'

export const MIN_MAX_DATE = 'MIN_MAX_DATE'

export const NOT_EMPTY_WITH_SPACE_MAX_LENGTH = 'NOT_EMPTY_WITH_SPACE_MAX_LENGTH'

export const MAX_LENGTH = 'MAX_LENGTH'

export const MIN_LENGTH_NUMBER = 0

export const MAX_LENGTH_OPTIMIZATION = 30

export const MAX_LENGTH_GLOBAL = 100
/**
 * Button
 */
export const startOptimization = 'Start optimization'
export const startBaseline = 'Create baseline'
export const editBaseline = 'Edit baseline'

export const startDataImport = 'Start data import'

export const addDataset = 'Add dataset'

export const addTalent = 'Add talent'

export const addCategory = 'Add category'

export const editCategory = 'Update category'

export const editTalent = 'Update'

export const addLabel = 'Add'

export const ADD_BUTTON = 'Add'

export const EDIT_BUTTON = 'Update'

export const removeLabel = 'Remove'

export const updateExcludeInclude = 'Update Include/ Exclude'

export const startNewOptimization = 'Start New Optimization'

export const latestReport = `${new Date().toISOString()} Test UAT - Full Optimization`

/**
 * User role names
 */
export const ROLE_ADMIN = 'Admin'

/**
 * Dashboard
 */

export const noOptimizationDataAvailable = 'Create new optimization'

/**
 * Acceptance Tooltips
 */
export const ACCEPTANCE_IS_MANUALLY_UPDATED =
  'This optimized item has been manually updated'
export const ACCEPTANCE_IS_OUTDATED =
  'This booking is related to an outdated part of the Live State'
/**
 * Extra constants
 */

export const suggestedEmployee = 'suggestedEmployee'

export const assignedEmployee = 'assignedEmployee'

export const success = 'SUCCESSFUL'

export const started = 'STARTED'

export const failed = 'FAILED'

export const inprogress = 'INPROGRESS'
export const partiallySuccess = 'PARTIALLY_SUCCESSFUL'

export const y = 'YES'

export const n = 'NO'

export const counts = 'Count'

export const hours = 'Hours'

export const isReportDownload = true

export const submit = 'Submit'

export const labelCategory = 'labelCategory'

export const topLabel = 'Label Count'

export const topLocation = 'Top 6 Locations'

export const assignedTitle = 'Assigned'

export const emptyObject = {}

export const addLabelNote =
  'Labels are not added again if a talent already has a label.'

export const removeLabelNote =
  'Labels are only removed from the talents that have those labels.'

export const importStatus = {
  [success]: success,
  [failed]: failed,
  [inprogress]: 'IN PROGRESS',
  [started]: started,
  [partiallySuccess]: 'PARTIALLY SUCCESSFUL',
}

export const defaultPage = 1
export const limit = 100

export const emptyValue = 'Blank'

export const addAction = 'add'

export const name = 'name'

export const modifyApiObj = {
  key: label,
  labelKey: name,
  modifiedKey: modifiedLabel,
}

export const isRunning = [inprogress, started]

export const isRunningAndFailed = [...isRunning, failed]
const hashTable = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
]

export const uid = () => {
  const uuid = []
  for (let i = 0; i < 35; i += 1) {
    if (i === 7 || i === 12 || i === 17 || i === 22) {
      uuid[i] = minusSymbol
    } else {
      uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)]
    }
  }
  return uuid.join('')
}

export const setTotalOverviewWidth = {
  1: 15,
  2: 30,
  3: 45,
  4: 60,
  5: 70,
}
export const setSubTotalOverviewWidth = {
  1: 15,
  2: 25,
  3: 35,
  4: 45,
  5: 55,
}

export const newJoiner = 'newJoiner'

export const exclude = 'exclude'

export const coreTeam = 'coreTeam'

export const reqHrs = 'requestedHours'

export const genHrs = 'genericHours'

export const hrs = 'hours'

export const count = 'count'

export const timeFte = 'timeFte'

export const totalScore = 'Total Score'

export const totalFitScore = 'FIT Score'

export const timeScore = 'Time Score'

export const employeeDetail = 'employeeDetails'

export const planningCounts = 'Job Start Date / Job End Date'

export const white = '#FFFFFF'
export const steelBlue = '#3C678E'
export const downy = '#59C8B1'
export const flamenco = '#FF7400'
export const doveGray = '#666666'
export const error = '#DD3B4E'
export const jordyBlue = '#64A2DC'
export const tradewind = '#50AC9A'
export const purple = '#9c27b0'

export const OBJECT = 'object'

export const ARRAY = 'array'

export const STRING = 'string'

export const BOOLEAN = 'boolean'

export const NUMBER = 'number'

export const localeConstant = 'locale'

export const show = 'Show'

export const hide = 'Hide'

export const optimizationStatus = 'optimizationStatus'

export const kpis = 'KPIs'

export const optimizations = 'Optimizations'

export const moreTheBetter = 'More the better'

export const lessTheBetter = 'Less the better'

export const inputTypeSelect = 'select'

export const uuidConstant = 'uuid'

export const noPlanningFound = 'No job found'

export const noDetailChangesFound = 'No detail change found'

export const employeeLabels = 'employeeLabels'

export const empModifiedLabel = 'empModifiedLabel'

export const bookingModifiedLabel = 'bookingModifiedLabel'

export const PASSWORD = 'PASSWORD'

export const KPI_OVERVIEW = 'kpiOverview'

export const BASELINE_BOOKINGS = 'baselineBookings'
export const BASELINE_EMPLOYEES = 'baselineEmployees'
export const FILTERED_EMPLOYEES = 'filteredEmployees'
export const FILTERED_BOOKINGS = 'filteredBookings'

export const btnVariant = {
  outlined: 'outlined',
  contained: 'contained',
}

export const btnColor = {
  primary: 'primary',
  secondary: 'secondary',
}

export const btnSize = {
  large: 'large',
  small: 'small',
  medium: 'medium',
}

export const btnType = {
  submit: 'submit',
  cancel: 'cancel',
  button: 'button',
  move: 'move',
  delete: 'delete',
  tooltipBtn: 'tooltipBtn',
}

export const backBtnTitle = 'Back'

export const nextBtnTitle = 'Next'

export const importDataBtnTitle = 'Import data'

export const validateBtnTitle = 'Validating data'

export const downloadLogsBtnTitle = 'Download logs'

export const differenceConstant = 'difference'

export const hoursConstant = 'hours'

export const rangeConstant = 'range'

export const dateRangeConstant = 'dateRange'

export const fitConstant = 'fit'

export const timeConstant = 'time'

export const timeFTEConstant = 'timeFte'

export const entryDateConstant = 'entryDate'

export const leaveDateConstant = 'leaveDate'

export const cursorPointerStyle = 'pointer'

export const showCheckBoxNone = 'none'

export const suggestedEmployeeConstant = 'Suggested Talent'

export const assignedEmployeeConstant = 'Assigned Talent'

export const tooltipPlacement = { left: 'left', right: 'right' }

export const slashSeparator = ` ${forwardSlashSymbol} `

export const operatingUnit = 'Operating Unit'

export const included = 'Included'

export const FTE = 'FTE'

export const grade = 'Grade'

export const assignedHours = 'Assigned Hours'

export const unassignedHours = 'Unassigned Hours'

export const MIN_VALUE = 0

export const MIN_ONE = 1

export const defaultType = 'application/json'
export const multipartFormDataType = 'multipart/form-data'
export const urlEncodedType = 'application/x-www-form-urlencoded'

export const errorStatusCodes = [401, 404, 500, 400, 422, 4000]

export const noEntries = 'No entries found'

export const percentageValue = 'percentageValue'

export const nullEmptyValues = [null, undefined, 'null', '']

export const noEntriesWithTip =
  'No entries found. Tip: Hidden columns are not searched, show them to include them in search.'

export const TOTAL_HOURS = 'totalHours'

export const SIXTY_SEC = 60

export const TEN_SEC = 10

export const boolValues = [
  'TRUE',
  'True',
  'true',
  true,
  'FALSE',
  'False',
  'false',
  false,
]

export const xlsxConst = 'xlsx'

export const csvConst = 'csv'

export const BASE_EXPORT_OPTIONS = [
  {
    key: csvConst,
    value: 'Export as CSV',
  },
  {
    key: xlsxConst,
    value: 'Export as XLSX',
  },
]

export const EXPORT_OPTIONS_WITH_BOOKING_PERIOD = [
  {
    key: csvConst,
    exportBy: 'booking',
    value: 'Export as CSV (aggregate, not suitable for re-import)',
  },
  {
    key: xlsxConst,
    exportBy: 'booking',
    value: 'Export as XLSX (aggregate, not suitable for re-import)',
  },
  {
    key: csvConst,
    exportBy: 'booking_period',
    value: 'Export as CSV (detailed, suitable for re-import)',
  },
  {
    key: xlsxConst,
    exportBy: 'booking_period',
    value: 'Export as XLSX (detailed, suitable for re-import)',
  },
]

export const UNASSIGNED_CONST = 'unassigned'

export const ASSIGNED_CONST = 'assigned'

export const minMaxDate = 'minMaxDate'

export const hideIgnoredMessage = 'Hide ignored bookings'

export const showIgnoredMessage = 'Show ignored bookings'

export const RETURN_POSITIVE = MIN_ONE

export const RETURN_NEGATIVE = -1

export const ASC_SORT = 'asc'

export const DESC_SORT = 'desc'

export const ACCEPTED = 'ACCEPTED'

export const REJECTED = 'REJECTED'

export const UNDECIDED = 'UNDECIDED'

export const NONE = 'NONE'

export const ACCEPTANCE_STATE_ENUM = [REJECTED, UNDECIDED, ACCEPTED, NONE]

export const acceptanceStateLabels = ['Reject', 'Undecided', 'Accept']

export const showMore = 'Show more'

export const showLess = 'Show less'

export const jobDetailsPath = 'jobDetails'

export const jobDataPath = 'jobData'

export const jobKpisPath = 'jobKpis'

export const graphDataPath = 'graphData'

export const isLoadingPath = 'isLoading'

export const LIVE_PLAN = 'livePlan'

export const BEFORE = 'before'

export const AFTER = 'after'

export const INVISIBLE_CHARACTER = '\u200B'

export const defaultReportText = 'Default report'

export const defaultOverviewText = 'Default overview'

export const maxBookingPeriodHours = 9999

export const MAX_PRIORITY = 9999

// Max allow concurrently running optimizations
export const MAX_ALLOWED_RUN_OPT = 2

// Max allow concurrently pending optimizations
export const MAX_ALLOWED_PENDING_OPT = 5

// Max allow concurrently baseline
export const MAX_ALLOWED_BASELINE = 10

export const EMPLOYEES_COLOR = '#c0ded7'

export const WO_COLOR = '#dbe6f0'

export const MATCHING_CORE = 'MatchingCore'

export const ONLY_SHOWS_TOTAL_FIGURES_MESSAGE =
  'Only shows total figures. It does not consider filters nor period'

// MOBILE

export const CLOSE_NAVBAR_WIDTH = 600

export const LINK_DEVICE_TEXT = 'LINK'

export const UNLINK_DEVICE_TEXT = 'UNLINK'

export const LINKED_DEVICES_TEXT = 'Linked devices'

export const REGISTERED_DEVICES_PATH = {
  parent: 'notifications',
  child: 'registeredDevices',
}

export const NOTIFICATIONS = 'Notifications'

export const REGISTER_THIS_DEVICE_STRING = 'Register this device'

// Other
export const NO_SELECTION_STRING = 'No selection'

export const HOURS_IN_A_YEAR = 365 * 24
