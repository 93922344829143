import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  kpiCardContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  kpiCardPercentage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  kpiCardPercentagePlaceHolder: {
    height: '50px',
  },
  boxGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [[0, -15]],
    [theme.breakpoints.down('1700')]: {
      margin: [[0, -10]],
    },
  },
  boxItem: {
    padding: [[0, 15]],
    width: '33.333%',
    boxSizing: 'border-box',
    marginBottom: 20,
    [theme.breakpoints.down('1700')]: {
      padding: [[0, 10]],
    },
    [theme.breakpoints.down('1190')]: {
      width: '100%',
    },
  },
  customCard: {
    height: '100%',
    borderRadius: 5,
    backgroundColor: '#fff',
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      zIndex: 2,
      boxShadow:
        '0 2rem 2rem 0 hsl(0deg 0% 100% / 40%), 0 0.8rem 1.5rem rgb(0 0 0 / 30%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
      '& > div': {
        width: '100%',
        height: '100%',
        right: 0,
        top: 0,
      },
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      height: '100%',
    },
  },
  overviewCard: { minHeight: '214px !important' },
  successFullText: {
    fontSize: 26,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    marginBottom: 15,
    color: theme.palette.success.main,
    '& svg': {
      marginLeft: 7,
    },
  },
  importDate: {
    fontSize: 13,
    marginTop: 5,
  },
  employeeDataText: {
    flexWrap: 'wrap',
    '& span': {
      color: 'rgba(0,0,0,0.5)',
    },
    '& h6': {},
  },
  subTitle: {
    textTransform: 'capitalize',
  },
  pendingText: {
    color: theme.palette.warning.main,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    margin: '0 auto',
    '& .MuiPaper-root, & .MuiDialog-container': {
      width: '100%',
    },
    '& .MuiStepper-root': {
      padding: [[24, 0, 0, 0]],
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  customBg: {
    content: "''",
    position: 'absolute',
    right: 10,
    top: 10,
    borderRadius: 5,
    width: 66,
    transition: 'all 0.5s',
    height: 66,
  },
  modalBody: {
    marginBottom: 10,
  },
  modalTitle: {
    fontWeight: 500,
    paddingRight: 5,
  },
  modalMainTitle: {
    fontSize: 26,
    marginBottom: 0,
    lineHeight: 'normal',
    '& button': {
      position: 'absolute',
      right: 5,
      top: 5,
    },
  },
  modalDetailContent: {
    paddingTop: 10,
  },
  resetContainer: {
    padding: theme.spacing(3),
    justifyContent: 'flex-start',
  },
  statusIcon: {
    '& img': {
      maxWidth: 23,
      marginLeft: 10,
    },
    '& svg': {
      marginLeft: 10,
      width: 25,
      verticalAlign: -1,
      height: 22,
    },
  },
  statusTitle: {
    fontWeight: 500,
  },
  noDataAvailable: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 500,
    verticalAlign: 'middle',
    color: theme.palette.text.hint,
  },
  chartDesign: {
    display: 'flex',
    alignItems: 'center',
  },
  valueChange: {
    marginLeft: 10,
  },
  positives: {
    '& span': {
      color: `${theme.palette.success.main} !important`,
      verticalAlign: 'middle',
      fontSize: 16,
      paddingLeft: 2,
    },
    '& svg': {
      color: `${theme.palette.success.main} !important`,
      verticalAlign: 'middle',
      fontSize: 16,
    },
  },
  negatives: {
    '& span': {
      color: `${theme.palette.error.main} !important`,
      verticalAlign: 'middle',
      fontSize: 16,
      paddingLeft: 2,
    },
    '& svg': {
      color: `${theme.palette.error.main} !important`,
      verticalAlign: 'middle',
      fontSize: 16,
    },
  },
  zeros: {
    '& span': {
      color: `${theme.palette.text.hint} !important`,
      verticalAlign: 'middle',
      fontSize: 16,
    },
    '& svg': {
      color: `${theme.palette.text.hint} !important`,
      verticalAlign: 'middle',
      fontSize: 16,
    },
  },
  formButtonsRight: {
    textAlign: 'right',
    '& button + button': {
      marginLeft: 10,
    },
  },
  updateValueDiv: {
    display: 'flex',
    marginTop: 25,
    justifyContent: 'space-between',
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  formContent: {
    width: '100%',
  },
  switchClass: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  launchpadGrid: {
    position: 'relative',
    '& > .MuiGrid-item:last-child': {
      paddingBottom: 0,
    },
  },
  launchpadGridCol: {
    [theme.breakpoints.down('1600')]: {
      maxWidth: '100% !important',
      flexBasis: '100% !important',
    },
  },
  launchpadChart: {
    position: 'relative',
    minHeight: 220,
    height: '100%',
    zIndex: 9,
    '& > div': {
      width: 200,
      position: 'absolute',
      top: '50%',
      left: -40,
      transform: 'translateY(-50%)',
      bottom: 0,
    },
  },
  launchPadTable: {
    height: 170,
    '& .MuiTableCell-root': {
      padding: 8,
      fontSize: 12.5,
    },
    '& .ScrollbarsCustom-Content': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiGrid-root': {
      padding: 0,
      margin: 0,
    },
  },
  launchpadDetailCard: {
    height: '100%',
    [theme.breakpoints.up('1600')]: {
      '& .MuiGrid-item:last-child': {
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.down('1280')]: {
      height: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
    },
    '& > .MuiGrid-item': {
      flexBasis: 'auto',
      width: '100%',
      [theme.breakpoints.down('1600')]: {
        maxWidth: '50%',
        flexBasis: '50%',
      },
      [theme.breakpoints.down('1280')]: {
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    '& h5': {
      marginBottom: 17,
      paddingTop: 4,
    },
  },
  launchpadCustomCardBody: {
    paddingBottom: '16px !important',
    paddingTop: '16px !important',
    '& .MuiGrid-grid-sm-5': {
      maxWidth: '43%',
      flexBasis: '43%',
    },
    '& .MuiGrid-grid-sm-7': {
      maxWidth: '57%',
      flexBasis: '57%',
    },
  },
  launchpadCard: {
    position: 'relative',
    minHeight: 230,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      boxShadow:
        '0 2rem 2rem 0 hsl(0deg 0% 100% / 40%), 0 0.8rem 1.5rem rgb(0 0 0 / 30%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
      '& > div': {
        boxShadow: 'none',
      },
    },
    '& > h5': {
      position: 'absolute',
      top: 24,
      left: 28,
      right: 28,
      zIndex: 9,
      [theme.breakpoints.down('1025px')]: {
        fontSize: '1.18rem',
      },
    },
  },
  noDataFound: {
    textAlign: 'center',
    borderRadius: 5,
    width: 'auto',
    padding: '9px 20px',
    color: '#bfbfbf',
    '& h3': {
      fontWeight: 600,
    },
  },
  flagCount: {
    [theme.breakpoints.down('1600')]: {
      maxWidth: '100% !important',
      flexBasis: '100% !important',
    },
    [theme.breakpoints.down('1280')]: {
      position: 'absolute',
      right: 0,
      bottom: 4,
      width: '50%',
      minHeight: 255,
      display: 'flex',
    },
    [theme.breakpoints.down('960')]: {
      position: 'relative',
      bottom: 0,
    },
  },
  rowHover: {
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
    },
  },
  tableMargin: {
    marginTop: 20,
  },
  widthMinHeight: {
    minHeight: '252px !important',
    '& .MuiPaper-root': {
      height: 'auto !important',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  countMargin: {
    marginRight: '10px',
  },
  formWrap: {
    width: '100%',
  },
  autoComplete: {
    '& .MuiAutocomplete-popperDisablePortal': {
      position: 'fixed',
    },
  },
  autoCompleteTalent: {
    '& .MuiAutocomplete-popperDisablePortal': {
      position: 'inherit',
    },
  },
  legendSpace: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  datasetStepper: {
    width: '100%',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      maxWidth: 750,
      minWidth: 600,
      margin: [[-60, 'auto', 0]],
      [theme.breakpoints.down('1405')]: {
        margin: [[-60, 0, 0, 'auto']],
        paddingRight: 0,
        maxWidth: 600,
      },
      [theme.breakpoints.down('1067')]: {
        margin: [[0, 'auto']],
        paddingRight: 0,
        maxWidth: 550,
      },
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      marginTop: 5,
    },
  },
  csvColumns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      maxWidth: 460,
      width: '100%',
    },
    '& .MuiSvgIcon-root': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  exampleHint: {
    marginTop: 5,
    display: 'block',
    maxWidth: 470,
    minHeight: 20,
    '& span': {
      display: 'block',
      color: '#8c8c8c',
    },
  },
  formButtons: {
    textAlign: 'right',
    '& button': {
      marginRight: 10,
    },
  },
  removeMargin: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
    '& .MuiInputLabel-root': {
      top: -16,
    },
  },
  csvTable: {
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root:first-child': {
        width: '35%',
      },
    },
  },
  errorBlock: {
    width: '100%',
    display: 'inline-block',
    borderBottom: '1px dashed #c1c1c1',
    paddingBottom: 10,
    paddingLeft: 20,
    marginBottom: 5,
    '& span': {
      float: 'left',
      width: '50%',
    },
  },
  isRequired: {
    fontWeight: 700,
    '& span': {
      marginLeft: 5,
      color: theme.palette.error.main,
    },
  },
  matchingExample: {
    display: 'block',
    margin: '6px 0 -10px',
  },
  errorReportInfo: {
    fontWeight: 600,
    '& svg': {
      verticalAlign: '-3px',
      width: 20,
      height: 23,
      marginRight: 5,
    },
    '& svg, & span': {
      color: theme.palette.error.main,
    },
  },
  moreErrors: {
    width: 'fit-content !important',
    cursor: 'context-menu',
  },
  successIcon: {
    verticalAlign: 'bottom',
    width: 20,
    height: 23,
    marginRight: 5,
    color: '#59c8b1',
  },
  successInfoMsg: {
    fontWeight: 500,
  },
  totalSuccess: {
    color: '#59c8b1',
  },
  stepTwoMargin: {
    marginTop: 15,
  },
  paginationButtons: {
    float: 'right',
    '& button': {
      marginRight: 15,
    },
  },
  displayBlock: {
    display: 'block',
  },
  displayFlex: {
    display: 'flex',
  },
  contentHover: {
    width: 'fit-content',
    cursor: 'pointer',
  },
  labelNote: {
    width: '100%',
    backgroundColor: '#FFC59F',
    color: '#4a3636',
    marginBottom: 10,
    marginTop: -10,
    padding: 10,
    borderRadius: 5,
  },
  infoIcon: {
    width: 17,
    height: 17,
    marginLeft: 10,
    verticalAlign: 'middle',
  },
  dataImportHead: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > div:first-child': {
      flex: [[0, 0, 'auto']],
    },
  },
  workOpportunityTitle: {
    '& .MuiPaper-root': {
      [theme.breakpoints.down('1660')]: {
        margin: [[-60, 0, 0, 'auto']],
        paddingRight: 0,
        maxWidth: 600,
      },
      [theme.breakpoints.down('1220')]: {
        margin: [[0, 'auto']],
      },
    },
  },
  bannerStyle: {
    marginBottom: 10,
  },
  tableContainer: {
    width: '100%',
    '& .MuiTable-root': {
      borderCollapse: 'inherit',
      '& .MuiTableCell-head': {
        whiteSpace: 'nowrap',
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        backgroundColor: '#fff',
        padding: 10,
      },
    },
    '& .MuiToolbar-root': {
      padding: [[0, 16]],
    },
  },
  mapLabelTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root:not(last-child)': {
        width: '35%',
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root:not(last-child)': {
        width: '35%',
        minWidth: 410,
        '& .MuiBox-root': {
          '& span': {
            overflowWrap: 'anywhere',
          },
        },
      },
    },
  },
  exportDropdown: {
    '& .MuiMenu-paper': {
      minWidth: 165,
    },
  },
  exportBtn: {
    height: 40,
    borderRadius: 4,
    '& svg': {
      marginLeft: 5,
      transition: 'all 0.3s ease-in-out',
    },
  },
  dropdownOpen: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  ignoredDownload: {
    width: '100%',
    flexDirection: 'column',
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root:last-child': {
        '& .MuiTableCell-root': {
          borderBottom: 'none !important',
        },
      },
    },
  },
  downlaodText: {
    marginTop: '10px',
    position: 'absolute',
    bottom: 0,
  },
  stepTitleSize: {
    fontWeight: 500,
    margin: [[15, 0]],
    borderBottom: '1px dashed #e4e4e4',
    width: 'fit-content',
  },
  expandPaper: {
    margin: theme.spacing(1),
    marginBottom: 10,
    marginLeft: 0,
    padding: 10,
    backgroundColor: '#fff',
    border: '1px solid #dddddd',
    boxShadow: '0 0 8px rgba(0,0,0,0.15)',
    borderRadius: 5,
  },
  root: {
    width: 42,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'primary',
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 22,
    height: 20,
    borderRadius: '24%',
  },
  track: {
    backgroundColor: '#ff7400',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  countSwitch: {
    marginRight: 'auto',
  },
  uploadSteps: {
    maxWidth: 690,
    overflow: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  labelCountForm: {
    marginTop: -12,
    marginLeft: 'auto',
    minWidth: 104,
    maxWidth: 150,
  },
}))

export default useStyles
