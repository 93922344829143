import { notEmptyOrNull } from '../../utils/constant'
import {
  launchpadEditLabelsCategory,
  launchpadFormPath,
  launchpadLabels,
  launchpadLabelsCategory,
} from '../formPath.description'

const commonFormAttr = {
  name: {
    label: 'Name *',
    type: 'text',
    isRequired: true,
    error: ['Name is not valid'],
    pattern: notEmptyOrNull,
    value: '',
    fullWidth: true,
    autoFocus: true,
  },
  description: {
    label: 'Description *',
    type: 'text',
    isRequired: true,
    value: '',
    error: ['Description is not valid'],
    pattern: notEmptyOrNull,
    fullWidth: true,
  },
}

const labelCategoryAttr = {
  labelCategory: {
    label: '',
    type: 'hidden',
    isRequired: false,
    error: false,
    pattern: notEmptyOrNull,
  },
}

const categoryFormAttr = {
  weight: {
    label: 'Weight *',
    value: 1,
    type: 'number',
    isRequired: true,
    error: false,
    pattern: notEmptyOrNull,
    fullWidth: true,
  },
  type: {
    label: '',
    value: 'REQUIRED',
    type: 'select',
    isRequired: false,
    error: false,
    pattern: notEmptyOrNull,
  },
  color: {
    label: 'Choose color *',
    type: 'hidden',
    isRequired: false,
    value: '#000',
    error: false,
    pattern: notEmptyOrNull,
  },
}

const formAttributes = {
  row1: {
    formPath: {
      parent: launchpadFormPath,
      child: launchpadLabels,
    },
    formAttributes: {
      ...commonFormAttr,
      ...labelCategoryAttr,
    },
  },
}

export const ddCategoryType = {
  type: [
    { label: 'INFORMATIONAL', value: 'INFORMATIONAL' },
    { label: 'OPTIONAL', value: 'OPTIONAL' },
    { label: 'REQUIRED', value: 'REQUIRED' },
    { label: 'PROHIBITED', value: 'PROHIBITED' },
    { label: 'AVOIDED', value: 'AVOIDED' },
  ],
}

export const form = [
  {
    type: 'table',
    fields: [formAttributes],
  },
]

const cloneFormAttributes = JSON.parse(
  JSON.stringify({
    row1: {
      formPath: {
        parent: launchpadFormPath,
        child: launchpadLabels,
      },
      formAttributes: {
        ...commonFormAttr,
        ...categoryFormAttr,
        ...labelCategoryAttr,
      },
    },
  }),
)
cloneFormAttributes.row1.formAttributes.color.type = 'color'
cloneFormAttributes.row1.formAttributes.color.isRequired = true
cloneFormAttributes.row1.formPath.child = launchpadLabelsCategory

export const categoryForm = [
  {
    type: null,
    fields: [cloneFormAttributes],
  },
]
export const editCategoryForm = [
  {
    type: null,
    fields: [
      {
        row1: {
          ...cloneFormAttributes.row1,
          formPath: {
            parent: launchpadFormPath,
            child: launchpadEditLabelsCategory,
          },
        },
      },
    ],
  },
]

const editFormAttributes = {
  row1: {
    ...formAttributes.row1,
    formAttributes: {
      ...formAttributes.row1.formAttributes,
      id: {
        label: '',
        type: 'hidden',
        isRequired: false,
        error: false,
        pattern: notEmptyOrNull,
      },
    },
  },
}

export const editForm = [
  {
    type: 'table',
    fields: [editFormAttributes],
  },
]
export const buttonAttributes = (btnTitle, handleClick, onSubmit) => [
  {
    variant: 'contained',
    color: 'primary',
    size: 'large',
    value: btnTitle,
    type: 'add',
    disableElevation: true,
    onClick: onSubmit,
  },
  {
    variant: 'contained',
    color: 'inherit',
    size: 'large',
    value: 'Cancel',
    type: 'cancel',
    disableElevation: true,
    onClick: handleClick,
  },
]

export const columns = [
  {
    name: 'id',
    label: 'ID',
    options: { display: false },
  },
  {
    name: 'name',
    label: 'Label Name',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'countBookings',
    label: 'Work Opportunity Count',
    hyperlink: true,
    redirect: '/work-opportunities',
    payload: 'bookingLabels',
  },
  {
    name: 'countEmployees',
    label: 'Talent Count',
    hyperlink: true,
    redirect: '/talents',
  },
  {
    name: 'lastUpdatedAt',
    label: 'Updated at',
  },
  {
    name: 'lastUpdatedBy',
    label: 'Updated by',
    isName: true,
  },
  {
    name: 'actions',
    label: 'Actions',
  },
]

export const formPath = {
  parent: launchpadFormPath,
  child: launchpadLabels,
}

export const palette = [
  { bgColor: '#3C678E', color: '#ffffff' },
  { bgColor: '#4D83B5', color: '#ffffff' },
  { bgColor: '#64A2DC', color: '#ffffff' },
  { bgColor: '#abcce6', color: '#000000' },
  { bgColor: '#38786b', color: '#ffffff' },
  { bgColor: '#50AC9A', color: '#ffffff' },
  { bgColor: '#59C8B1', color: '#000000' },
  { bgColor: '#65DBC2', color: '#000000' },
  { bgColor: '#EE6D01', color: '#ffffff' },
  { bgColor: '#FF7400', color: '#ffffff' },
  { bgColor: '#FFA366', color: '#000000' },
  { bgColor: '#FFC59F', color: '#000000' },
  { bgColor: '#990000', color: '#ffffff' },
  { bgColor: '#ff0000', color: '#ffffff' },
  { bgColor: '#e06666', color: '#ffffff' },
  { bgColor: '#f8bcbc', color: '#000000' },
  { bgColor: '#111111', color: '#ffffff' },
  { bgColor: '#555', color: '#ffffff' },
  { bgColor: '#999', color: '#ffffff' },
  { bgColor: '#7f7d9c', color: '#ffffff' },
]

export const removeColumns = [
  'countBookings',
  'countEmployees',
  'lastUpdatedAt',
  'lastUpdatedBy',
]

export const searchableColumns = [
  'name',
  'description',
  'lastUpdatedAt',
  'lastUpdatedBy',
]
