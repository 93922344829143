import * as Sentry from '@sentry/react'
// import { initializeApp } from 'firebase/app'
// import { getMessaging, onMessage } from 'firebase/messaging'
import { createBrowserHistory } from 'history'
import { useEffect, useState } from 'react'
import ReactPiwik from 'react-piwik'
import { useDispatch, useSelector } from 'react-redux'

// import firebaseConfig from '../../presentation/notifications/firebaseConfig'
import {
  getMatomo,
  getSentry,
  setPiwikInstance,
} from '../../redux/actions/app.action'
import { apiEndPoints } from '../../utils/constant'

export const appContainer = () => {
  const [matomoData, setMatomoData] = useState(null)

  const dispatch = useDispatch()

  const data = useSelector((state) => state.app?.matomoState)
  const sentryData = useSelector((state) => state.app?.sentryState)
  const { isAuth } = useSelector((state) => state.app)

  const {
    analytics_enabled: analyticsEnabled,
    analytics_server_site_id: analyticsServerSiteId,
    analytics_server_url: analyticsServerUrl,
  } = data

  const {
    react_app_sentry_dsn: sentryDSN,
    react_app_sentry_traces_sample_rate: sampleRate,
    sentry_environment: sentryEnvironment,
  } = sentryData

  // const firebaseApp = initializeApp(firebaseConfig)
  // const messaging = getMessaging(firebaseApp)

  useEffect(() => {
    dispatch(getMatomo())
  }, [])

  useEffect(() => {
    if (isAuth) {
      dispatch(getSentry())
    }
  }, [isAuth])

  useEffect(() => {
    if (sentryDSN && sentryEnvironment) {
      // Initialize the sentry environment
      Sentry.init({
        dsn: sentryDSN,
        environment: sentryEnvironment,
        sampleRate,
        tunnel: `../../${apiEndPoints?.sentryTunnel}`,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      })
    }
  }, [sentryData])

  useEffect(() => {
    if (analyticsEnabled) {
      // eslint-disable-next-line no-new
      const piwik = new ReactPiwik({
        url: analyticsServerUrl,
        siteId: analyticsServerSiteId,
        trackErrors: true,
        enableLinkTracking: true,
        trackDocumentTitle: true,
      })
      setMatomoData({ piwik, ...data })
      dispatch(setPiwikInstance(piwik))
    } else {
      dispatch(setPiwikInstance(null))
    }
  }, [analyticsEnabled])

  // useEffect(() => {
  //   // Handle foreground messages
  //   onMessage(messaging, (payload) => {
  //     // Customize notification here
  //     const notificationTitle = payload?.notification?.title
  //     const notificationOptions = {
  //       body: payload?.notification?.body,
  //       icon: '/firebase-logo.png',
  //     }

  //     // eslint-disable-next-line no-new
  //     new Notification(notificationTitle, notificationOptions)
  //   })
  //   if ('setAppBadge' in navigator) {
  //     navigator.setAppBadge(7) // Sets the badge number to 7 for testing
  //   }
  // }, [])

  const trackAtConnect = false
  const history = createBrowserHistory()

  return { matomoData, history, trackAtConnect }
}
