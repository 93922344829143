import { useState } from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from '../../hooks/use-dispatch'
import {
  clearSessionAction,
  sidebarOpenedAction,
} from '../../redux/actions/app.action'
import { CLOSE_NAVBAR_WIDTH } from '../../utils/constant'
import { clearStateFn } from '../../utils/localStorage'

export const headerProfileContainer = () => {
  const { dispatch } = useDispatch()
  const [isOpen, toggle] = useState(null)

  const user = useSelector((state) => state.app.user)
  const group = useSelector((state) => state.app.group)
  const isSidebarOpen = useSelector((state) => state.app?.sidebarOpened)
  const isMobile = window.innerWidth <= CLOSE_NAVBAR_WIDTH
  const handleLogoutSession = () => {
    dispatch(clearSessionAction())
    clearStateFn()
  }
  const responsiveToggle = () => {
    toggle(null)
    if (isMobile && isSidebarOpen) {
      dispatch(sidebarOpenedAction())
    }
  }
  return {
    isOpen,
    toggle,
    user,
    group,
    handleLogoutSession,
    responsiveToggle,
  }
}
