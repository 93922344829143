import { CLOSE_NAVBAR_WIDTH } from '../../utils/constant'
import {
  ADD_PIWIK_INSTANCE,
  APP_AUTH,
  CHECK_MATOMO_STATUS,
  CHECK_SENTRY_STATUS,
  CLEAR_SESSION,
  LOCALE,
  MANAGE_SIDE_BAR_MENU,
  SIDEBAR_OPENED,
} from '../constants'

const isDefaultClosed = window.innerWidth <= CLOSE_NAVBAR_WIDTH

const initialState = {
  access_token: null,
  userData: {},
  sidebarOpened: !isDefaultClosed,
  manageSideBarMenu: {},
  isAuth: false,
  isSessionChecked: false,
  matomoState: {
    analytics_server_url: null,
    analytics_server_site_id: null,
    analytics_enabled: false,
  },
  sentryState: {
    react_app_sentry_dsn: null,
    react_app_sentry_traces_sample_rate: null,
    sentry_environment: null,
  },
  piwik: null,
}

const app = (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_AUTH:
      return { ...state, ...payload }

    case SIDEBAR_OPENED:
      return { ...state, sidebarOpened: !state.sidebarOpened }

    case LOCALE:
      return { ...state, locale: payload }

    case MANAGE_SIDE_BAR_MENU:
      return { ...state, manageSideBarMenu: payload }

    case CLEAR_SESSION:
      return {
        ...initialState,
        matomoState: state?.matomoState,
        piwik: state?.piwik,
        isSessionChecked: state.isSessionChecked,
      }

    case CHECK_MATOMO_STATUS:
      return { ...state, matomoState: payload }

    case CHECK_SENTRY_STATUS:
      return { ...state, sentryState: payload }

    case ADD_PIWIK_INSTANCE:
      return { ...state, piwik: payload }
    default:
      return state
  }
}
export default app
