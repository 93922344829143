import { MIN_MAX_DATE, OBJECT, POSITIVE_NUMBER } from '../../utils/constant'
import {
  checkUndefined,
  entries,
  equal,
  head,
  isArray,
  keys,
  length,
  ternary,
  typeOf,
  values,
} from '../../utils/javascript'
import { loadStateFn } from '../../utils/localStorage'
import validate from '../../utils/validation'
import {
  CLEAR_FORM,
  CLEAR_FORM_DATA,
  IS_REQUIRED,
  IS_VALID,
  ON_FORM_CHANGE,
  SET_DISABLED,
  SET_FLAG,
  SET_FORM_VALUES,
} from '../constants'

export const onChangeAction =
  (
    name,
    value,
    formPath,
    pattern,
    min = null,
    max = null,
    periodName = null,
    optionalName,
  ) =>
  async (dispatch, getState) => {
    const state = getState()
    const { parent, child } = formPath
    let clonedFormData = { ...state.form.formData }
    let clonedFieldErrors = { ...state.form.fieldErrors }

    let isClonedNestedForm = state.form.nestedForm[parent][child]
    if (
      typeof state.form.nestedForm[parent][child] === 'object' &&
      keys(state.form.nestedForm[parent][child])?.length === 0
    ) {
      isClonedNestedForm = false
    }

    clonedFormData = {
      ...clonedFormData,
      [parent]: {
        ...clonedFormData[parent],
        [child]: {
          ...clonedFormData[parent]?.[child],
          ...(isClonedNestedForm
            ? {
                [head(keys(JSON.parse(name)))]: {
                  ...clonedFormData[parent]?.[child]?.[
                    head(keys(JSON.parse(name)))
                  ],
                  [head(values(JSON.parse(name)))]: value,
                },
              }
            : { [name]: value }),
        },
      },
    }
    clonedFieldErrors = {
      ...clonedFieldErrors,
      [parent]: {
        ...clonedFieldErrors[parent],
        [child]: {
          ...clonedFieldErrors[parent]?.[child],
          ...(isClonedNestedForm
            ? {
                [head(keys(JSON.parse(name)))]: {
                  ...clonedFieldErrors[parent]?.[child]?.[
                    head(keys(JSON.parse(name)))
                  ],
                  [head(values(JSON.parse(name)))]: ternary(
                    !!state.form.fieldRequired[parent][child][
                      head(keys(JSON.parse(name)))
                    ][head(values(JSON.parse(name)))] || value,
                    !validate(
                      pattern,
                      value,
                      min,
                      max,
                      clonedFormData?.startDate,
                      clonedFormData?.endDate,
                    ),
                    false,
                  ),
                },
              }
            : {
                [name]: ternary(
                  !!state.form.fieldRequired[parent][child]?.[name] || value,
                  !validate(
                    pattern,
                    value,
                    min,
                    max,
                    clonedFormData[parent][child]?.startDate,
                    clonedFormData[parent][child]?.endDate,
                    periodName,
                  ),
                  false,
                ),
              }),
        },
      },
    }
    // date range checks
    const isDateRange = !!(name === 'startDate' || name === 'endDate')
    if (isDateRange) {
      const isDateRangeError = clonedFieldErrors[parent]?.[child][name]
      clonedFieldErrors[parent][child].startDate = isDateRangeError
      clonedFieldErrors[parent][child].endDate = isDateRangeError
    }
    const payload = {
      formData: clonedFormData,
      fieldErrors: clonedFieldErrors,
    }

    dispatch({ type: ON_FORM_CHANGE, payload })
    dispatch(
      isValid({
        parent,
        child,
        min,
        max,
        periodName,
        optionalName,
      }),
    )
  }

export const onClearableChangeAction =
  (
    name,
    value,
    formPath,
    pattern,
    min = null,
    max = null,
    periodName = null,
    optionalName,
  ) =>
  async (dispatch, getState) => {
    min = 0.1
    max = 9999
    const state = getState()
    const { parent, child } = formPath
    let clonedFormData = { ...state.form.formData }
    let clonedFieldErrors = { ...state.form.fieldErrors }

    let isClonedNestedForm = state.form.nestedForm[parent][child]
    if (
      typeof state.form.nestedForm[parent][child] === 'object' &&
      keys(state.form.nestedForm[parent][child])?.length === 0
    ) {
      isClonedNestedForm = false
    }

    clonedFormData = {
      ...clonedFormData,
      [parent]: {
        ...clonedFormData[parent],
        [child]: {
          ...clonedFormData[parent]?.[child],
          ...(isClonedNestedForm
            ? {
                [head(keys(JSON.parse(name)))]: {
                  ...clonedFormData[parent]?.[child]?.[
                    head(keys(JSON.parse(name)))
                  ],
                  [head(values(JSON.parse(name)))]: value,
                },
              }
            : { [name]: value }),
        },
      },
    }
    clonedFieldErrors = {
      ...clonedFieldErrors,
      [parent]: {
        ...clonedFieldErrors[parent],
        [child]: {
          ...clonedFieldErrors[parent]?.[child],
          ...(isClonedNestedForm
            ? {
                [head(keys(JSON.parse(name)))]: {
                  ...clonedFieldErrors[parent]?.[child]?.[
                    head(keys(JSON.parse(name)))
                  ],
                  [head(values(JSON.parse(name)))]: ternary(
                    !!state.form.fieldRequired[parent][child][
                      head(keys(JSON.parse(name)))
                    ][head(values(JSON.parse(name)))] || value,
                    !validate(
                      pattern,
                      value,
                      min,
                      max,
                      clonedFormData?.startDate,
                      clonedFormData?.endDate,
                    ),
                    false,
                  ),
                },
              }
            : {
                [name]: ternary(
                  (typeof state.form.fieldRequired[parent][child] ===
                    'object' &&
                    keys(state.form.fieldRequired[parent][child])?.length ===
                      0) ||
                    !!state.form.fieldRequired[parent][child]?.[name] ||
                    value,
                  !validate(
                    pattern,
                    value,
                    min,
                    max,
                    clonedFormData[parent][child]?.startDate,
                    clonedFormData[parent][child]?.endDate,
                    periodName,
                  ),
                  false,
                ),
              }),
        },
      },
    }
    // date range checks
    const isDateRange = !!(name === 'startDate' || name === 'endDate')
    if (isDateRange) {
      const isDateRangeError = clonedFieldErrors[parent]?.[child][name]
      clonedFieldErrors[parent][child].startDate = isDateRangeError
      clonedFieldErrors[parent][child].endDate = isDateRangeError
    }
    const payload = {
      formData: clonedFormData,
      fieldErrors: clonedFieldErrors,
    }

    dispatch({ type: ON_FORM_CHANGE, payload })
    dispatch(
      isClearableValid({
        parent,
        child,
        min,
        max,
        periodName,
        optionalName,
      }),
    )
  }

export const setFormValuesAction =
  (
    formPath,
    object,
    replaceFormData = false,
    optionalName,
    callIsValid = true,
  ) =>
  async (dispatch, getState) => {
    const { parent, child } = formPath
    const state = getState()
    let clonedFormData = { ...state.form.formData }

    if (replaceFormData) {
      clonedFormData = {
        ...clonedFormData,
        [parent]: {
          ...clonedFormData[parent],
          [child]: object,
        },
      }
    } else {
      entries(clonedFormData?.[parent]?.[child]).forEach(
        ([key, existValue]) => {
          const value = object[key]
          clonedFormData = {
            ...clonedFormData,
            [parent]: {
              ...clonedFormData[parent],
              [child]: {
                ...clonedFormData[parent][child],
                [key]: value || existValue,
              },
            },
          }
        },
      )
    }
    dispatch({
      type: SET_FORM_VALUES,
      payload: { clonedFormData },
    })
    if (callIsValid) {
      dispatch(isValid({ parent, child, optionalName }))
    }
  }

export const requiredAction =
  ({ form, isClear = true, callIsValid = false }) =>
  async (dispatch, getState) => {
    new Promise((resolve) => resolve(isClear && dispatch(clearForm()))).then(
      () => {
        const state = getState()
        let clonedFieldRequired = { ...state.form.fieldRequired }
        let oldIsValid = { ...state.form.isValid }
        let clonedFieldErrors = { ...state.form.fieldErrors }
        let clonedFormData = { ...state.form.formData }
        let clonedQueryString = { ...state.form.queryString }
        let clonedFieldDisabled = { ...state.form.fieldDisabled }
        let clonedFieldPattern = { ...state.form.pattern }
        let clonedFieldLocalStorage = { ...state.form.fieldLocalStorage }
        let clonedFormSettled = { ...state.form.formSettled }
        let clonedNestedForm = { ...state.form.nestedForm }
        let cFormPath = {}

        const setForm = (object, path, mapFieldsName, nestedObj) => {
          const { mapRequired, isOptionalForm, ...rest } = object
          return values(rest).forEach(
            (
              {
                isRequired,
                isQuery,
                value,
                disabled,
                pattern,
                formAttributes,
                formPath,
                fromLocalStorage,
                storageParam,
                mapRequired,
                checked,
              },
              index,
            ) => {
              if (formAttributes) {
                setForm(formAttributes, formPath)
              } else if (mapRequired) {
                setForm(
                  values(rest)[index],
                  path,
                  keys(rest)[index],
                  mapRequired,
                )
              } else {
                cFormPath = { ...path }
                const { parent, child } = path
                if (parent && child) {
                  const name = keys(rest)[index]
                  clonedFieldPattern = {
                    ...clonedFieldPattern,
                    [parent]: {
                      ...clonedFieldPattern[parent],
                      [child]: {
                        ...clonedFieldPattern[parent]?.[child],
                        ...ternary(
                          nestedObj,
                          {
                            [mapFieldsName]: {
                              ...clonedFieldPattern[parent]?.[child]?.[
                                mapFieldsName
                              ],
                              [name]: pattern,
                            },
                          },
                          { [name]: pattern },
                        ),
                      },
                    },
                  }
                  clonedFieldDisabled = {
                    ...clonedFieldDisabled,
                    [parent]: {
                      ...clonedFieldDisabled[parent],
                      [child]: {
                        ...clonedFieldDisabled[parent]?.[child],
                        ...ternary(
                          nestedObj,
                          {
                            [mapFieldsName]: {
                              ...clonedFieldDisabled[parent]?.[child]?.[
                                mapFieldsName
                              ],
                              [name]: disabled || false,
                            },
                          },
                          { [name]: disabled || false },
                        ),
                      },
                    },
                  }
                  clonedFieldLocalStorage = {
                    ...clonedFieldLocalStorage,
                    [parent]: {
                      ...clonedFieldLocalStorage[parent],
                      [child]: {
                        ...clonedFieldLocalStorage[parent]?.[child],
                        ...ternary(
                          nestedObj,
                          {
                            [mapFieldsName]: {
                              ...clonedFieldLocalStorage[parent]?.[child]?.[
                                mapFieldsName
                              ],
                              [name]: fromLocalStorage || false,
                            },
                          },
                          { [name]: fromLocalStorage || false },
                        ),
                      },
                    },
                  }
                  clonedQueryString = {
                    ...clonedQueryString,
                    [parent]: {
                      ...clonedQueryString[parent],
                      [child]: {
                        ...clonedQueryString[parent]?.[child],
                        ...ternary(
                          nestedObj,
                          {
                            [mapFieldsName]: {
                              ...clonedQueryString[parent]?.[child]?.[
                                mapFieldsName
                              ],
                              [name]: isQuery || false,
                            },
                          },
                          { [name]: isQuery || false },
                        ),
                      },
                    },
                  }
                  clonedFieldErrors = {
                    ...clonedFieldErrors,
                    [parent]: {
                      ...clonedFieldErrors[parent],
                      [child]: {
                        ...clonedFieldErrors[parent]?.[child],
                        ...ternary(
                          nestedObj,
                          {
                            [mapFieldsName]: {
                              ...clonedFieldErrors[parent]?.[child]?.[
                                mapFieldsName
                              ],
                              [name]: false,
                            },
                          },
                          { [name]: false },
                        ),
                      },
                    },
                  }

                  clonedFormData = {
                    ...clonedFormData,
                    [parent]: {
                      ...clonedFormData[parent],
                      [child]: {
                        ...clonedFormData[parent]?.[child],
                        ...ternary(
                          nestedObj,
                          {
                            [mapFieldsName]: {
                              ...clonedFormData[parent]?.[child]?.[
                                mapFieldsName
                              ],
                              [name]: fromLocalStorage
                                ? loadStateFn(storageParam) || value || checked
                                : value || checked,
                            },
                          },
                          {
                            [name]: fromLocalStorage
                              ? loadStateFn(storageParam) || value || checked
                              : value || checked,
                          },
                        ),
                      },
                    },
                  }
                  clonedFieldRequired = {
                    ...clonedFieldRequired,
                    [parent]: {
                      ...clonedFieldRequired[parent],
                      [child]: {
                        ...clonedFieldRequired[parent]?.[child],
                        ...ternary(
                          nestedObj,
                          {
                            [mapFieldsName]: {
                              ...clonedFieldRequired[parent]?.[child]?.[
                                mapFieldsName
                              ],
                              [name]: isRequired || false,
                            },
                          },
                          {
                            [name]: isRequired || false,
                          },
                        ),
                      },
                    },
                  }
                  oldIsValid = {
                    ...oldIsValid,
                    [parent]: {
                      ...Object.fromEntries(
                        entries(oldIsValid[parent]).filter(
                          ([, v]) => !checkUndefined(v),
                        ),
                      ),
                      [child]: isOptionalForm || false,
                    },
                  }
                  clonedFormSettled = {
                    ...clonedFormSettled,
                    [parent]: {
                      ...clonedFormSettled[parent],
                      [child]: true,
                    },
                  }
                  clonedNestedForm = {
                    ...clonedNestedForm,
                    [parent]: {
                      ...clonedNestedForm[parent],
                      [child]: ternary(nestedObj, true, false),
                    },
                  }
                }
              }
            },
          )
        }
        setForm(form)
        const payload = {
          fieldRequired: clonedFieldRequired,
          fieldErrors: clonedFieldErrors,
          queryString: clonedQueryString,
          fieldDisabled: clonedFieldDisabled,
          formData: clonedFormData,
          pattern: clonedFieldPattern,
          updatedIsValid: oldIsValid,
          fieldLocalStorage: clonedFieldLocalStorage,
          formSettled: clonedFormSettled,
          nestedForm: clonedNestedForm,
        }
        dispatch({ type: IS_REQUIRED, payload })
        if (callIsValid && length(keys(cFormPath))) dispatch(isValid(cFormPath))
      },
    )
  }

const getClearPayload = (path, data, key) => ({
  [path.parent]: ternary(
    path?.child,
    {
      ...data?.[path.parent],
      [path?.child]: ternary(
        path?.childObject,
        {
          ...data?.[path.parent]?.[path?.child],
          [path?.childObject]: {},
        },
        equal(key, 'formSettled') ? false : {},
      ),
    },
    equal(key, 'formSettled') ? false : {},
  ),
})

export const clearForm = (formPath) => async (dispatch, getState) => {
  let payload = {}

  const formState = { ...getState()?.form }

  keys(formState).forEach((key) => {
    if (isArray(formPath)) {
      formPath?.forEach((obj) => {
        payload = {
          ...payload,
          isValid: {},
          [key]: {
            ...(payload?.[key] || formState?.[key]),
            ...getClearPayload(obj, payload?.[key] || formState?.[key], key),
          },
        }
      })
    }
    if (formPath && !isArray(formPath)) {
      const restValid = formState?.isValid?.[formPath?.parent]
      payload = {
        ...(payload || formState),
        isValid: {
          ...(payload?.[key] || formState?.[key]),
          [formPath?.parent]: {
            ...restValid,
            [formPath?.child]: undefined,
          },
        },
        [key]: {
          ...(payload?.[key] || formState?.[key]),
          ...getClearPayload(formPath, payload?.[key] || formState?.[key], key),
        },
      }
    }
    if (!formPath) {
      payload = {
        ...(payload || formState),
        [key]: {},
      }
    }
  })

  dispatch({
    type: CLEAR_FORM,
    payload,
  })
}

export const isClearableValid =
  ({
    parent,
    child,
    min = null,
    max = null,
    periodName = null,
    optionalName,
    fallbackRequired = { hours: true, startDate: true, endDate: true },
    fallbackPattern = {
      hours: POSITIVE_NUMBER,
      startDate: MIN_MAX_DATE,
      endDate: MIN_MAX_DATE,
    },
  }) =>
  (dispatch, getState) => {
    const state = getState()
    let clonedFieldPattern = state.form.pattern?.[parent]?.[child]
    if (keys(clonedFieldPattern)?.length === 0) {
      clonedFieldPattern = fallbackPattern
    }
    const clonedFormData = state.form.formData?.[parent]?.[child]
    const clonedMappingFormData = state.form.formData?.[parent]?.[optionalName]
    let clonedFieldRequired = state.form.fieldRequired?.[parent]?.[child]
    if (keys(clonedFieldRequired)?.length === 0) {
      clonedFieldRequired = fallbackRequired
    }
    const clonedMappingRequired =
      state.form.fieldRequired?.[parent]?.[optionalName]
    const clonedNestedForm = state.form.nestedForm?.[parent]?.[child]
    const isValidCloned = { ...state.form.isValid }

    const getValid = (clonedFieldRequired) =>
      !entries(clonedFieldRequired)
        .map(([name, required]) => {
          if (clonedNestedForm && typeOf(required, OBJECT)) {
            return getValid(required)
          }
          if (optionalName) {
            if (required) {
              return validate(
                clonedFieldPattern[name],
                clonedFormData?.[name] || clonedMappingFormData?.[name],
                min,
                max,
                clonedFormData?.startDate,
                clonedFormData?.endDate,
                periodName,
              )
            }
            return true
          }
          if (required || clonedFormData[name]) {
            return validate(
              clonedFieldPattern[name],
              clonedFormData?.[name],
              min,
              max,
              clonedFormData?.startDate,
              clonedFormData?.endDate,
              periodName,
            )
          }
          return true
        })
        .some((filtered) => !filtered)
    dispatch({
      type: IS_VALID,
      payload: {
        isValid: {
          ...isValidCloned,
          [parent]: ternary(
            optionalName,
            {
              ...isValidCloned[parent],
              [child]: getValid(clonedFieldRequired),
              [optionalName]: getValid(clonedMappingRequired),
            },
            {
              ...isValidCloned[parent],
              [child]: getValid(clonedFieldRequired),
            },
          ),
        },
      },
    })
  }

export const clearFileUploadAction =
  (formPath, name) => async (dispatch, getState) => {
    const state = getState()
    const clonedFormData = { ...state.form.formData }
    const clonedFieldRequired = { ...state.form.fieldRequired }
    const clonedFieldErrors = { ...state.form.fieldErrors }
    clonedFormData[formPath.parent][formPath.child][name] = ''
    const isValidField = validate(
      name,
      clonedFormData[formPath.parent][formPath.child][name],
    )
    clonedFieldErrors[formPath.parent][formPath.child] = {
      ...clonedFieldErrors[formPath.parent][formPath.child],
      [name]: clonedFieldRequired[formPath.parent][formPath.child][name]
        ? !isValidField
        : false,
    }
    dispatch({
      type: SET_FORM_VALUES,
      payload: { clonedFormData, clonedFieldErrors },
    })
    dispatch(isValid(formPath))
  }

export const isValid =
  ({
    parent,
    child,
    min = null,
    max = null,
    periodName = null,
    optionalName,
  }) =>
  (dispatch, getState) => {
    const state = getState()
    const clonedFieldPattern = state.form.pattern?.[parent]?.[child]
    const clonedFormData = state.form.formData?.[parent]?.[child]
    const clonedMappingFormData = state.form.formData?.[parent]?.[optionalName]
    const clonedFieldRequired = state.form.fieldRequired?.[parent]?.[child]
    const clonedMappingRequired =
      state.form.fieldRequired?.[parent]?.[optionalName]
    const clonedNestedForm = state.form.nestedForm?.[parent]?.[child]
    const isValidCloned = { ...state.form.isValid }

    const getValid = (clonedFieldRequired) =>
      !entries(clonedFieldRequired)
        .map(([name, required]) => {
          if (clonedNestedForm && typeOf(required, OBJECT)) {
            return getValid(required)
          }
          if (optionalName) {
            if (required) {
              return validate(
                clonedFieldPattern[name],
                clonedFormData?.[name] || clonedMappingFormData?.[name],
                min,
                max,
                clonedFormData?.startDate,
                clonedFormData?.endDate,
                periodName,
              )
            }
            return true
          }
          if (required || clonedFormData[name]) {
            return validate(
              clonedFieldPattern[name],
              clonedFormData?.[name],
              min,
              max,
              clonedFormData?.startDate,
              clonedFormData?.endDate,
              periodName,
            )
          }
          return true
        })
        .some((filtered) => !filtered)
    dispatch({
      type: IS_VALID,
      payload: {
        isValid: {
          ...isValidCloned,
          [parent]: ternary(
            optionalName,
            {
              ...isValidCloned[parent],
              [child]: getValid(clonedFieldRequired),
              [optionalName]: getValid(clonedMappingRequired),
            },
            {
              ...isValidCloned[parent],
              [child]: getValid(clonedFieldRequired),
            },
          ),
        },
      },
    })
  }

export const setDisableAction = (fieldsWithFormPath) => (dispatch) => {
  dispatch({
    type: SET_DISABLED,
    payload: fieldsWithFormPath,
  })
}

export const clearFormAction = (formPath) => async (dispatch, getState) => {
  const { parent, child } = formPath
  const state = { ...getState()?.form }

  delete state.isValid?.[parent]?.[child]
  delete state.formData?.[parent]?.[child]
  delete state.fieldErrors?.[parent]?.[child]
  delete state.fieldRequired?.[parent]?.[child]
  delete state.fieldLocalStorage?.[parent]?.[child]
  delete state.queryString?.[parent]?.[child]
  delete state.pattern?.[parent]?.[child]
  delete state.errors?.[parent]?.[child]
  delete state.formSettled?.[parent]?.[child]
  const payload = { state }
  dispatch({
    type: CLEAR_FORM_DATA,
    payload,
  })
}

export const needFormDataAction = (payload) => async (dispatch) =>
  dispatch({ type: SET_FLAG, payload })

export const resetApexChart = () => async (dispatch) => {
  dispatch(
    setFormValuesAction(
      { parent: 'apexChart', child: 'resetValue' },
      new Date(),
      true,
    ),
  )
}
