import { Switch } from '@material-ui/core'
import React from 'react'

const RPCSwitch = ({
  value,
  name,
  title,
  checked,
  onChange,
  error,
  disabled,
  Icon,
  classes,
  readOnly = false,
}) => (
  <>
    {title && <label htmlFor={name}>{title}</label>}
    <Switch
      name={name}
      onChange={(e) => onChange(e)}
      checked={checked}
      color="primary"
      error={error}
      value={value}
      disabled={disabled || readOnly}
      classes={{
        root: classes?.root,
        switchBase: classes?.switchBase,
        thumb: classes?.thumb,
        track: classes?.track,
        checked: classes?.checked,
      }}
      {...(Icon && { checkedIcon: <Icon />, icon: <Icon /> })}
    />
  </>
)

export default RPCSwitch
