import { ReactComponent as AdminSvg } from '../assets/svg/admin.svg'
import { ReactComponent as MissionControlSvg } from '../assets/svg/aspaara_mission_control.svg'
import { ReactComponent as DataGathering } from '../assets/svg/data_gathering.svg'
import { ReactComponent as DataRest } from '../assets/svg/data-reset.svg'
import { ReactComponent as Labels } from '../assets/svg/labels.svg'
import { ReactComponent as Config } from '../assets/svg/setting.svg'
import Dataset from '../shared/icons/Datasets'
import EditIcon from '../shared/icons/EditIcon'
import KpiIcon from '../shared/icons/KpiIcon'
import Report from '../shared/icons/Report'
import TalentIcon from '../shared/icons/TalentIcon'
import TalentProfile from '../shared/icons/TalentProfile'
import WorkOpportunity from '../shared/icons/WorkOpportunity'
import {
  ADMIN_CONFIGURATION,
  ADMIN_DELETE,
  ADMIN_REPORT,
  DATA_GATHERING_TALENT,
  DATA_IMPORT,
  KPI_GRAPH_REPORTS,
  KPI_OVERVIEW,
  LABELS,
  MISSION_CONTROL,
  TALENT,
  TALENT_PROFILE,
  WORK_OPPORTUNITY,
} from './rolesNPermission.description'

const baseStructure = [
  {
    label: 'Mission Control',
    link: '/mission-control',
    icon: <MissionControlSvg />,
    manageSideBarMenu: {
      name: 'mission-control',
      value: false,
    },
    pagePermission: MISSION_CONTROL,
  },
  {
    label: 'KPIs',
    icon: <KpiIcon />,
    onlyAdmin: false,
    children: [
      {
        label: 'KPI Overview',
        link: '/kpis/overview',
        icon: <KpiIcon />,
        pagePermission: KPI_OVERVIEW,
        onlyAdmin: false,
      },
      {
        label: 'Graph Reports',
        link: '/kpis/graph-reports',
        icon: <Report />,
        pagePermission: KPI_GRAPH_REPORTS,
        onlyAdmin: false,
      },
    ],
  },
  {
    label: 'Talents',
    link: '/talents',
    icon: <TalentIcon />,
    pagePermission: TALENT,
  },
  {
    label: 'Work Opportunities',
    link: '/work-opportunities',
    icon: <WorkOpportunity />,
    pagePermission: WORK_OPPORTUNITY,
  },
  {
    label: 'Talent Profile',
    link: '/talent-profile',
    icon: <TalentProfile />,
    pagePermission: TALENT_PROFILE,
  },
  {
    label: 'Labels',
    link: '/labels',
    icon: <Labels />,
    pagePermission: LABELS,
  },
  {
    label: 'Data Gathering',
    icon: <DataGathering />,
    children: [
      {
        label: 'Talent',
        link: '/data-gathering/talent',
        icon: <DataGathering />,
        pagePermission: DATA_GATHERING_TALENT,
      },
    ],
  },
  {
    label: 'Admin',
    icon: <AdminSvg />,
    children: [
      {
        label: 'Report',
        link: '/admin/reports',
        icon: <Report />,
        pagePermission: ADMIN_REPORT,
      },
      {
        label: 'Data Reset',
        link: '/admin/data-reset',
        icon: <DataRest />,
        pagePermission: ADMIN_DELETE,
      },
      {
        label: 'Settings',
        link: '/admin/settings',
        icon: <Config />,
        pagePermission: ADMIN_CONFIGURATION,
      },
      {
        label: 'Edit Profile',
        link: '/admin/edit-profile',
        icon: <EditIcon />,
        pagePermission: ADMIN_CONFIGURATION,
      },
    ],
  },
]

export const manualStructure = [
  {
    label: 'Data Import',
    link: '/data-import',
    icon: <Dataset />,
    manageSideBarMenu: {
      name: 'data-import',
      value: false,
    },
    pagePermission: DATA_IMPORT,
  },
  ...baseStructure,
]

export const retainStructure = [
  {
    label: 'Data Sync',
    link: '/data-import',
    icon: <Dataset />,
    manageSideBarMenu: {
      name: 'data-import',
      value: false,
    },
    pagePermission: DATA_IMPORT,
  },
  ...baseStructure,
]
