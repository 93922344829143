import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { sidebarContainer } from '../../../container/layout/sidebar.container'
import { permissionPages } from '../../../description/rolesNPermission.description'
import RPCDot from '../../../shared/RPCDot'
import RPCList from '../../../shared/RPCList'
import { ROLE_ADMIN } from '../../../utils/constant'
import { equal, ternary } from '../../../utils/javascript'

const SidebarLink = ({
  link,
  icon,
  manageSideBarMenu,
  label,
  children,
  nested,
  subLabel,
  isActive = false,
  compClick = null,
  needToHideText = true,
  pagePermission,
  checkPermission = true,
  onlyAdmin,
  isMainSidebar = true,
}) => {
  const {
    sidebarMenuClasses: classes,
    isLinkActive,
    activeChildLink,
    onClick,
    isOpen,
    sidebarOpened,
    classes: compClasses,
    showActiveClass,
  } = sidebarContainer(
    link,
    manageSideBarMenu,
    children,
    isActive,
    compClick,
    isMainSidebar,
  )
  const hasPermission = ternary(
    !children,
    permissionPages(pagePermission),
    children?.some((obj) => permissionPages(obj?.pagePermission)),
  )
  const userRole = useSelector((state) => state.app?.role?.name)
  return (
    <>
      <ListItem
        button
        {...(!isLinkActive && { component: link && Link })}
        onClick={onClick}
        className={classes?.link}
        {...(!isLinkActive && { to: link })}
        disableRipple
        classes={{
          root: classNames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
            [compClasses.hide]: ternary(
              onlyAdmin,
              !equal(userRole, ROLE_ADMIN),
              checkPermission && !hasPermission,
            ),
          }),
        }}
      >
        <ListItemIcon
          className={classNames(classes.linkIcon, {
            [classes.linkIconActive]:
              activeChildLink || isLinkActive || showActiveClass,
          })}
          {...(!sidebarOpened && { title: label })}
        >
          {icon || <RPCDot color={isLinkActive && 'primary'} />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classNames(classes.linkText, {
              [classes.linkTextActive]:
                isLinkActive || activeChildLink || showActiveClass,
              [classes.linkTextHidden]: needToHideText && !sidebarOpened,
            }),
          }}
          primary={label}
        />
        {subLabel}
      </ListItem>
      {children && (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
          className={classes?.nestedList}
        >
          <RPCList component="div" disablePadding>
            {children.map((childrenLink, index) => (
              <SidebarLink
                key={index}
                sidebarOpened={sidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </RPCList>
        </Collapse>
      )}
    </>
  )
}

export default React.memo(SidebarLink)
