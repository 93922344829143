import { Chip, Menu } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'

import { palette } from '../description/labels/label.description'
import { uid } from '../utils/constant'
import { equal, gt, length } from '../utils/javascript'
import useStyles from './css/labelChip.style'
import RPCButton from './RPCButton'

const RenderLabels = ({ value, classes }) => {
  const labelClasses = useStyles()
  const [toggleOpen, setToggleOpen] = useState(null)

  const handleClick = (event) => {
    setToggleOpen(event.currentTarget)
  }

  const chipDetail = (data) =>
    data?.map(({ name, color }) => (
      <span key={uid()}>
        <Chip
          className={classNames(classes.chip, {
            [classes.chipMargin]: gt(length(value), 1),
          })}
          style={{
            background: color || '#ffe2ca',
            color:
              palette?.find(({ bgColor }) => equal(bgColor, color))?.color ||
              '#fff',
          }}
          label={name}
        />
      </span>
    ))

  return (
    <>
      {chipDetail(value?.slice(0, 2))}
      {gt(length(value), 2) && (
        <RPCButton className={labelClasses.blueColor} onClick={handleClick}>
          {`+${length(value) - 2}`}
        </RPCButton>
      )}

      <Menu
        anchorEl={toggleOpen}
        keepMounted
        open={Boolean(toggleOpen)}
        onClose={() => setToggleOpen(null)}
        className={labelClasses.labelPoppver}
        classes={{
          paper: labelClasses.colorPickerMenu,
        }}
      >
        {chipDetail(value?.slice(2))}
      </Menu>
    </>
  )
}
export default RenderLabels
