import { makeStyles } from '@material-ui/core'
import YAML from 'json-to-pretty-yaml'
import moment from 'moment'
import { useSelector } from 'react-redux'

import PlusIcon from '../shared/icons/PlusIcon'
import { count, hrs } from '../utils/constant'
import {
  checkIncludes,
  entries,
  equal,
  gt,
  head,
  keys,
  last,
  length,
  lt,
  returnValue,
  ternary,
} from '../utils/javascript'
import { loadStateFn } from '../utils/localStorage'
import { btnConstantContainer } from './btnConstant.container'

const useStyles = makeStyles(() => ({
  iconStyle: {
    paddingRight: 5,
  },
  '@media (max-width: 600px)': {
    btnMargin: {
      backgroundColor: 'rgb(224, 224, 224) !important',
      width: '100%',
      marginTop: '20px !important',
    },
    fullWidth: {
      width: '100%',
    },
  },
  '@media (min-width: 600px)': {
    btnMargin: {
      marginTop: '20px !important',
      marginLeft: '5px !important',
    },
  },
}))

export const addBtnAttr = (btnTitle, isAdd = true) => {
  const classes = useStyles()

  const { outlined, primary, large } = btnConstantContainer()

  const btnAttributes = {
    variant: outlined,
    color: primary,
    size: large,
    value: (
      <>
        {isAdd && <PlusIcon className={classes.iconStyle} />}
        {`${btnTitle}`}
      </>
    ),
    disableElevation: true,
  }
  return btnAttributes
}

export const setButtons = (
  btnTitle,
  handleClick,
  isPreview = false,
  isCancel = true,
  disabled,
  isSecondaryCustom = false,
  secondaryButtonText,
  secondaryButtonVariant = 'contained',
  secondaryButtonColor = 'inherit',
  secondaryButtonClick = () => {},
  secondaryButtonType = 'cancel',
  secondaryButtonSize = 'large',
) => {
  const classes = useStyles()
  const buttonAttributes = [
    {
      ...(isCancel && {
        variant: 'contained',
        color: 'inherit',
        size: 'large',
        value: 'Cancel',
        type: 'cancel',
        disableElevation: true,
        onClick: handleClick,
      }),
    },
    {
      ...(isPreview && {
        variant: 'contained',
        color: 'primary',
        size: 'large',
        value: 'Preview',
        type: 'button',
        disabled: true,
        disableElevation: true,
      }),
    },
    {
      variant: 'contained',
      color: 'primary',
      size: 'large',
      value: btnTitle,
      type: 'submit',
      ...(isSecondaryCustom && { className: classes.fullWidth }),
      disabled,
      disableElevation: true,
    },
    {
      ...(isSecondaryCustom && {
        variant: secondaryButtonVariant,
        color: secondaryButtonColor,
        size: secondaryButtonSize,
        value: secondaryButtonText,
        type: secondaryButtonType,
        className: classes.btnMargin,
        disableElevation: true,
        onClick: secondaryButtonClick,
      }),
    },
  ].filter((obj) => !equal(length(keys(obj))))
  return { buttonAttributes }
}

const fileTypes = {
  'application/json': 'yml',
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'csv',
}

export const downloadCSV = ({
  csv,
  filename,
  type = 'text/csv',
  isReport = false,
  isImportErrorLog = false,
}) => {
  const data = ternary(type.includes('json'), JSON.stringify(csv), csv)
  let d = null

  if (isImportErrorLog) {
    d = YAML.stringify(csv)
  }

  const csvFile = new Blob(
    [ternary(checkIncludes('json', type) && d, d, data)],
    {
      type: ternary(
        checkIncludes('xlsx', type),
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        type,
      ),
    },
  )

  const downloadLink = document.createElement('a')
  downloadLink.download = ternary(
    isReport,
    ternary(
      checkIncludes('xlsx', type),
      `${filename}.xlsx`,
      `${filename}.${fileTypes[type]}`,
    ),
    `Aspaara_MatchingCore_${filename}`,
  )
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}

const excelFileTypes = {
  'application/json': 'yml',
  'text/xslx': 'xslx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/csv': 'csv',
}
export const downloadExcel = ({
  excel,
  filename,
  type = 'xlsx',
  isReport = false,
  response,
}) => {
  const data = ternary(type.includes('json'), JSON.stringify(excel), excel)

  const excelFile = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8',
  })

  const downloadLink = document.createElement('a')
  downloadLink.download = ternary(
    isReport,
    ternary(
      checkIncludes('xlsx', type),
      `${filename}.xlsx`,
      `${filename}.${excelFileTypes[type]}`,
    ),
    `Aspaara_MatchingCore_${filename}.xlsx`,
  )
  if (!filename) {
    const contentDisposition = response.headers['content-disposition']
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      // Extract the filename from the content disposition header
      const matches = /filename=([^;]+)/.exec(contentDisposition)
      if (matches != null && matches[1]) {
        // eslint-disable-next-line prefer-destructuring
        filename = matches[1]
        downloadLink.setAttribute('download', filename) // Update the filename attribute
      }
    }
  }

  downloadLink.href = window.URL.createObjectURL(excelFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}

export const downloadJSON = ({
  json,
  filename = 'error_logs',
  isReport = false,
  response,
}) => {
  const data = JSON.stringify(json)

  const jsonFile = new Blob([data], {
    type: 'application/json',
  })

  const downloadLink = document.createElement('a')
  downloadLink.download = ternary(
    isReport,
    `${filename}.json`,
    `Aspaara_MatchingCore_${filename}.json`,
  )
  if (!filename) {
    const contentDisposition = response.headers['content-disposition']
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      // Extract the filename from the content disposition header
      const matches = /filename=([^;]+)/.exec(contentDisposition)
      if (matches != null && matches[1]) {
        // eslint-disable-next-line prefer-destructuring
        filename = matches[1]
        downloadLink.setAttribute('download', filename) // Update the filename attribute
      }
    }
  }

  downloadLink.href = window.URL.createObjectURL(jsonFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}

export const getReplacedData = (clonedChildData, data) => {
  if (clonedChildData) {
    const ccd = [...clonedChildData]
    const index = ccd.findIndex((obj) => obj.uuid === data.uuid)
    if (~index) {
      ccd[index] = data
    }
    return ccd
  }
  return []
}

export const maxValues = (obj = {}, num = 0, flag) => {
  const sortedValue = entries(obj).sort(
    ([, v1], [, v2]) => returnValue(v2, flag) - returnValue(v1, flag),
  )
  let l1 = {}
  let rest = {
    count: 0,
    hours: 0,
  }

  entries(sortedValue).forEach(([, vl], ind) => {
    if (lt(ind, num) && !equal(head(vl), 'rest') && !equal(head(vl), 'Other')) {
      l1 = {
        ...l1,
        [head(vl)]: last(vl),
      }
    }
    if (gt(ind, num) || equal(head(vl), 'rest') || equal(head(vl), 'Other')) {
      rest = {
        ...rest,
        count: rest.count + returnValue(last(vl), count),
        hours: rest.hours + Number(returnValue(last(vl), hrs)?.toFixed()),
      }
    }
  })

  if (gt(rest.count, 0)) {
    l1.Other = rest
  }

  return { topSix: l1 }
}

export const setCustomFilter = (getCustomFilterNames, defaultColumns) => {
  defaultColumns.forEach((val) => {
    if (!getCustomFilterNames.find((obj) => equal(obj.name, val))) {
      getCustomFilterNames.push({
        name: val,
        type: 'dropdown',
      })
    }
  })
  return getCustomFilterNames
}

export const defaultColumns = ({ localKey, columns }) => {
  const col = JSON.parse(loadStateFn('defaultColumns'))
  const reOrderedColumns = []
  const tableColumn = col?.[localKey]
  const modifiedColumns = tableColumn
    ? columns.map((obj) => ({
        ...obj,
        options: {
          ...obj.options,
          display: tableColumn[obj?.name],
        },
      }))
    : columns

  const isAcceptanceStatePresent = columns.some(
    ({ name }) => name === 'acceptanceState',
  )

  keys(tableColumn).forEach((k) => {
    reOrderedColumns.push(modifiedColumns.find((obj) => equal(obj.name, k)))
  })

  if (
    isAcceptanceStatePresent &&
    !reOrderedColumns.some(({ name }) => name === 'acceptanceState')
  ) {
    const acceptanceStateColumn = modifiedColumns.find(
      ({ name }) => name === 'acceptanceState',
    )
    reOrderedColumns.push(acceptanceStateColumn)
  }

  return ternary(
    tableColumn,
    reOrderedColumns.filter(Boolean),
    modifiedColumns.filter(Boolean),
  )
}

export const checkPrevSaved = (localKey) => {
  const userName = useSelector((state) => state.app?.user?.username)
  const groupName = useSelector((state) => state.app?.group?.name)

  const settingConfig = JSON.parse(
    loadStateFn(`${userName}-${groupName}-setting-configuration`),
  )
  const prevSaved =
    settingConfig?.[localKey] &&
    moment(settingConfig?.[localKey]?.period?.max).diff(
      settingConfig?.[localKey]?.period?.max,
      'years',
    ) <= 1

  const hasDateLength = equal(
    length(keys(settingConfig?.[localKey]?.period)),
    2,
  )
  const isPeriodChanged = settingConfig?.[localKey]?.isPeriodChanged

  return { prevSaved, hasDateLength, isPeriodChanged }
}
